<template>
  <div class="ml-2 mt-2">
    <h3>Functions</h3>
    <p>
      helloWorld: {{ hwResponse }}<br/>
    </p>
    <button class="btn btn-primary" @click="helloWorld()">helloWorld</button>
    <br/><br/>
    <p>
      Authenticated User JWT: 
      <pre>{{ JWTResponse }}</pre>
    </p>
    <button class="m-1 btn btn-primary" @click="login()">login</button>
    <button class="m-1 btn btn-primary" @click="upgradeAuth()">upgrade Auth</button>
    <button class="m-1 btn btn-primary" @click="logout()">logout</button>
    <br/>
    <br/>
    <br/>
    <br/>
  </div>
</template>

<script>
import { getLog } from "@/services/log";
import { firebase } from "@/services/firebase";
import { helloWorld, upgradeAuth } from "@/services/functions";
let log = getLog("test-functions");

import "firebase/auth";
let auth = firebase.auth();
let googleAuthProvider = () => new firebase.auth.GoogleAuthProvider();

export default {
  name: 'app',
  components: {
  },
  data() {
    return {
      hwResponse: "",
      JWTResponse: "N/A",
    };
  },
  mounted() {
    let that = this;
    this.AuthStateChangedListener = auth.onAuthStateChanged(user => {
      log.log("onAuthStateChanged", user);
      that.showUser(user);
    });
    this.IdTokenChangedListener = auth.onIdTokenChanged(user => {
      log.log("onIdTokenChanged", user);
      that.showUser(user);
    });
  },
  beforeDestroy() {
    if (this.AuthStateChangedListener)
      this.AuthStateChangedListener();
    if (this.IdTokenChangedListener)
      this.IdTokenChangedListener();
  },
  methods: {
    // Authentication and claims
    async showUser(user) {
      if (!user)
        this.JWTResponse = "NULL";
      else
        this.JWTResponse = await user.getIdTokenResult();
    },
    login() {
      var provider = googleAuthProvider();
      auth.signInWithPopup(provider).then(function(result) {
        log.log("signIn=", result);
      }).catch(function(error) {
        log.error("signInWithPopup error=", error);
      });
    },
    logout() {
      auth.signOut();
    },
    upgradeAuth() {
      log.log("upgradeAuth");
      if (!auth.currentUser) {
        log.log("no user to upgrade");
        return;
      }
      upgradeAuth().then((r) => {
        log.log("response: ", JSON.stringify(r));
        if (r.data) {
          if (r.data.need_refresh)
            auth.currentUser.getIdToken(true);
        } else
          this.JWTResponse = "upgradeAuth failed";
      });
    },
    // Basic loop
    helloWorld() {
      log.time("helloWorld");
      helloWorld({name:"john"}).then((r) => { 
        log.timeEnd("helloWorld");
        this.hwResponse = JSON.stringify(r);
      });
    }
  }
}
</script>

<style scoped>
pre {
 font-size: 60%;
}
</style>